.hero {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}

.heroLeftHalf {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 50%;
  height: 100%;
  background-color: #c1ff72;
}

.heroRightHalf {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 50%;
  height: 100%;
  background-color: #333333;
}

.heroLogoDiv {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 6vh;
  margin-top: 1vh;
  margin-left: 1vh;
}

.heroLogoDiv h1 {
  font-family: 'League Spartan', sans-serif;
  font-size: 2rem;
  font-weight: bold;
  margin: 0;
  padding: 0;
  color: #333333;
}

.heroLogoDiv img {
  width: auto;
  height: 100%;
}

.heroContent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start !important;
  align-items: flex-start !important;
  width: 100%;
  height: auto;
}

.heroContent h1 {
  font-family: 'League Spartan', sans-serif;
  background: linear-gradient(to left, #333333, #1c1c1c);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 6rem;
  font-weight: bold;
  margin: 0;
  padding: 0;
}

.heroContent h4 {
  font-family: 'Roboto', sans-serif;
  color: black;
  font-size: 1.6rem;
  font-weight: 300;
  margin: 0;
  padding: 0;
}

.heroHeaderRight {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  gap: 25px;
  height: auto;
  padding: 20px;
  box-sizing: border-box;
}

.heroHeaderRight p {
  font-family: 'Roboto', sans-serif;
  color: rgb(231, 231, 231);
  font-size: 1.2rem;
  font-weight: 350;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.heroHeaderRight p:hover {
  color: #c1ff72;
}

.ctaButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto;
  background-color: #333333;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  background-color: #c1ff72;
  font-size: 1.2rem;
  cursor: pointer;
  transition: ease-in-out 0.1s;
}

.ctaButton:hover {
  background-color: #b0ff48;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  scale: 1.1;
}

.ctaButton:active {
  transition: 0.08s;
  background-color: black;
  color: white;
  scale: 0.8;
}

.heroContentRight {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: auto;
}

.bigOffer {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  color: rgb(231, 231, 231);
}

.bigOffer p {
  margin: 0;
  font-size: 1.5rem;
  margin-bottom: 48px;
  font-weight: 200;
}

.bigOffer h1 {
  font-family: 'League Spartan', sans-serif;
  margin: 0;
  font-size: 15rem;
  background: linear-gradient(to right, rgb(231, 231, 231), #979797);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.subHeading {
  color: rgb(231, 231, 231);
  font-size: 1.5rem;
  font-weight: 200;
  margin-top: -10px;
}

.subHeading span {
  color: #c1ff72;
  font-weight: 600;
}

.heroRelativPos {
  position: relative;
  left: 0;
  top: 45vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.arrowDown {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: 30px;
  background-color: rgb(231, 231, 231);
  margin-top: 2rem;
  transition: ease-in-out 0.1s;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  cursor: pointer;
}

.arrowDown:hover {
  background-color: #c1ff72;
  scale: 1.1;
}

.arrowDown:active {
  transition: 0.08s;
  background-color: #b0ff48;
  scale: 1;
}

@media screen and (max-width: 1350px) {
  .bigOffer h1 {
    font-size: 10rem;
  }

  .heroContent h1 {
    font-size: 4rem;
  }
}

@media screen and (max-width: 850px) {
  .bigOffer h1 {
    font-size: 15rem;
  }

  .hero {
    flex-direction: column;
  }

  .heroLeftHalf {
    width: 100%;
  }

  .heroRightHalf {
    width: 100%;
  }

  .heroRelativPos {
    top: 15vh;
  }

  .heroHeaderRight {
    position: absolute;
  }

  .heroHeaderRight p {
    color: #333;
  }
}

@media screen and (max-width: 600px) {
  .bigOffer h1 {
    font-size: 9rem;
  }
  .headerExpanded {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    position: fixed;
    top: 0;
    height: 100vh;
    backdrop-filter: blur(5px);
    border-radius: 0.8rem;
    z-index: 10;
    gap: 10px;
  }

  .headerExpandedItem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: 100px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    backdrop-filter: grayscale(100%);
    border-radius: 0.8rem;
    font-size: 1.2rem;
    background-color: rgba(231, 231, 231, 0.8);
    transition: ease-in-out 0.1s;
  }

  .closeIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 50px;
    border-radius: 25px;
    background-color: rgba(231, 231, 231, 0.35);
    margin-bottom: 2rem;
    transition: ease-in-out 0.1s;
  }

  .closeIcon:hover {
    scale: 1.1;
    background-color: red;
  }

  .headerExpandedItem:hover {
    background-color: #c1ff72;
    scale: 1.04;
  }

  .headerExpandedItem:active {
    background-color: #b0ff48;
    scale: 1;
  }

  .heroContent h1 {
    font-size: 3rem;
  }

  .heroContent h4 {
    font-size: 1.2rem;
  }

  .subHeading {
    font-size: 1.2rem;
  }
}
