.App {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  font-family: 'Rotobo', sans-serif;
  background-color: #333;
  overflow-y: hidden;
}

section {
  width: 100%;
}
