@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes buttonHover {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.05);
  }
}

.offer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  background-color: rgb(231, 231, 231);
}

.offerHeadingDiv h1 {
  font-family: 'League Spartan', sans-serif;
  font-size: 4rem;
  color: #333;
  text-align: center;
  animation: fadeIn 1s ease-out; /* Hinzugefügte Animation */
  margin-bottom: 0;
}

.offerHeadingDiv p {
  margin-bottom: 2rem;
  font-size: 1.3rem;
  text-align: center;
  width: 100%;
  margin-top: 0.2rem;
}

.offerDiv {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch; /* This ensures the cards are the same height */
  width: 100%;
  height: auto; /* Ensure auto height to accommodate the content */
  max-width: 1500px;
  min-height: 40vh; /* Optional: Set a min-height if needed */
  padding: 0.5rem;
  padding-bottom: 2rem;
  box-sizing: border-box;
}

.offerCard {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Distribute the space inside */
  align-items: center;
  width: 25%;
  margin: 1rem;
  padding: 1rem;
  padding-bottom: 11rem;
  border-radius: 1rem;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  height: auto; /* Use auto for better flexibility */
  animation: fadeIn 0.5s ease-out; /* Hinzugefügte Animation */
}

.offerCard:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  scale: 1.01;
  transition: transform 0.3s ease; /* Weichere Transition */
}

.offerCardHeader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(231, 231, 231);
  width: 100%;
  border-radius: 0.8rem;
  padding: 1rem;
  box-sizing: border-box;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.offerCardHeader h1 {
  font-family: 'League Spartan', sans-serif;
  font-size: 3rem;
  color: #333;
  margin: 0;
  margin-top: 1rem;
}

.offerCardHeader p {
  margin-bottom: 0;
  text-align: center;
}

.offerCardFooter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  padding: 1rem;
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
  transition: all 0.2s;
}

.offerCardFooter:hover {
  padding-bottom: 2rem;
}

.offerCardFooterInnerDiv {
  background-color: #333;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  border-radius: 0.8rem;
  color: rgb(231, 231, 231);
  padding: 0.5rem;
  box-sizing: border-box;
}

.offerCardFooterInnerDiv h1 {
  margin: 0;
  font-size: 3rem;
}

.offerCardCTAButton {
  background-color: rgb(231, 231, 231);
  color: #333;
  padding: 0.1rem;
  border: none;
  border-radius: 0.8rem;
  font-family: 'League Spartan', sans-serif;
  font-size: 1.3rem;
  margin-top: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-bottom: 0.8rem;
  width: 100%;
  text-align: center;
  box-sizing: border-box;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.offerCardCTAButton:hover {
  background-color: #333;
  color: rgb(231, 231, 231);
  transform: scale(1.05); /* Hinzugefügte Skalierung */
  animation: buttonHover 0.3s ease-out;
}

.offerCardCTAButtonSpezial:hover {
  background-color: #c1ff72 !important;
  color: #333 !important;
  padding: 0.6rem;
}

.offerCardCTAButton:active {
  transform: scale(0.98);
}

.offerCardFeatures {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  padding-top: 2rem;
  box-sizing: border-box;
  width: 100%;
}

.offerCardFeatures p {
  padding: 0.6rem;
  margin: 0;
  font-family: 'League Spartan', sans-serif;
  font-size: 1.3rem;
  width: 100%;
  text-align: center;
  border-top: 1px solid;
}

.arrowDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 60px;
  border-radius: 30px;
  background-color: #333;
  color: #c1ff72;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  transition: ease-in-out 0.1s;
}

.arrowOuterDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 2rem;
  margin-bottom: 2rem;
}

.arrowDiv:hover {
  transform: scale(1.1); /* Vergrößerung bei Hover */
}

.arrowDiv:active {
  transform: scale(0.98); /* Verkleinerung bei Aktivierung */
}

@media screen and (max-width: 1300px) {
  .offerCard {
    width: 80%;
    max-width: 500px;
  }

  .offerDiv {
    align-items: center;
  }
}

@media screen and (max-width: 550px) {
  .offerCard {
    width: 95%;
  }
}
