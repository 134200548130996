.footer footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  gap: 2rem;
  background-color: rgb(231, 231, 231);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.footer {
  width: 100%;
}

.iconDiv {
  gap: 20px;
  width: auto;
}
