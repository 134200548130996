.about {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
  padding-bottom: 2rem;
}

.about h1 {
  font-family: 'League Spartan', sans-serif;
  font-size: 4rem;
  color: #333;
}

.aboutContent {
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.aboutWerteOuterDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  max-width: 1500px;
}

.aboutWerteDiv {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 2rem;
}

.aboutWert {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #333;
  border-radius: 0.8rem;
  width: 25%;
  color: white;
  padding: 1rem;
  min-width: 200px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.aboutTeamDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 90%;
  max-width: 1500px;
}

.teamMember {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: auto;
  width: 33%;
  border-radius: 0.8rem;
  overflow: hidden;
  min-width: 280px;
}

.teamMember img {
  height: auto;
  width: 100%;
  object-fit: cover;
  border-radius: 0.8rem;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.aboutMemberDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 1%;
}

.teamMember h3 {
  margin-bottom: 0;
}

.teamMember p {
  margin-top: 0.2rem;
}

@media screen and (max-width: 1060px) {
  .aboutWerteDiv {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 850px) {
  .aboutMemberDiv {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 550px) {
  .teamMember {
    width: 90%;
  }

  .aboutWert {
    width: 180px;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 479px) {
  .aboutWert {
    width: 90%;
  }
}
