@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes scaleUp {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.05);
  }
}

@keyframes scaleDown {
  from {
    transform: scale(1.05);
  }
  to {
    transform: scale(1);
  }
}

.contactForm {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: auto;
  min-height: 100vh;
  width: 100%;
  background-color: #333;
}

.contactFormHeading h1 {
  font-family: 'League Spartan', sans-serif;
  font-size: 4rem;
  color: white;
}

.contactFormFields {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  padding: 2rem;
  box-sizing: border-box;
}

.contactFormFields label {
  display: flex;
  flex-direction: column;
  color: white;
  margin-bottom: 1.5rem;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.contactFormFields input,
.contactFormFields textarea {
  padding: 0.5rem;
  width: 100%;
  margin-top: 0.5rem;
  border: none;
  border-radius: 0.4rem;
  font-size: 1rem;
  box-sizing: border-box;
  font-family: 'Roboto';
}

.contactFormFields textarea {
  resize: none;
  height: 120px;
}

button[type='submit'] {
  padding: 0.8rem 1.2rem;
  background-color: #c1ff72;
  border: none;
  border-radius: 0.4rem;
  color: #333;
  font-size: 1rem;
  cursor: pointer;
  width: 100%;
  max-width: 400px;
  margin-top: 1rem;
}

button[type='submit']:hover {
  background-color: #a1e065;
}

.contactFormFields select,
.contactFormFields input[type='date'] {
  padding: 0.5rem;
  width: 100%;
  margin-top: 0.5rem;
  border: none;
  border-radius: 0.4rem;
  font-size: 1rem;
  box-sizing: border-box;
  font-family: 'Roboto';
}

.contactFormFields fieldset {
  border: 1px solid #ccc;
  padding: 1rem;
  margin-bottom: 1.5rem;
  border-radius: 0.4rem;
  color: white;
  box-sizing: border-box;
  width: 100%;
}

.contactFormFields legend {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.contactFormFields input[type='checkbox'] {
  margin-right: 0.5rem;
}
.contactInformationDiv,
.projectInformationDiv {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-width: 400px;
  animation: fadeIn 0.8s ease-in-out;
}

.contactFormContent {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  gap: 2rem;
  animation: fadeIn 0.8s ease-in-out;
  flex-wrap: wrap;
}

.confirmationMessage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  color: #333;
  background-color: rgb(231, 231, 231);
  padding: 1rem;
  border-radius: 0.8rem;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  transition: ease-in-out 0.2s;
  margin-top: 2rem;
  animation: fadeIn 0.4s ease-in-out;
}

@media screen and (max-width: 768px) {
  button[type='submit'] {
    width: 90%;
    max-width: 350px;
  }

  .contactFormFields label {
    width: 90%;
  }

  .contactFormFields fieldset {
    width: 90%;
  }
}
